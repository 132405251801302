html, body { 
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Noto Sans JP', sans-serif;
  color: #1d1d26;
}
* { box-sizing: border-box; }

#root, .App { height: 100%; }
.App {
  display: flex;
  flex-direction: column;
  min-width: 980px;
}

main { 
  flex: 1;
  overflow: auto;
  position: relative; /* to reset absolute position of descendants */
}

.mainContentWrap {
  padding: 10px 40px 50px;
  max-width: 990px;
  margin: 0 auto;
}

.app-loading {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 133px;
    display: inline-block;
    margin-bottom: 20px;
  }
}

h1 {
  font-weight: normal;
  font-size: 1.5em;
}

i.spacer { flex: 1; }

/***** 
 * Generic Form styles
 */
.field {
  padding: 0 1em 1em 0;
  display: inline-block;

  label { 
    font-weight: bold;
    font-size: 0.75em;
    padding-bottom: 0.4em;
    display: block;

    span {
      font-weight: normal;
    }
  }

  input, select, textarea {
    background-color: #f7fcff;
    border: 1px solid #e7e7e7;
    border-radius: 5px;
    padding: 0.3em 0.6em;
    display: block;
    font-size: 1.1em;
    font-family: inherit;
    width: 100%;
  }
}

form { 
  footer {
    padding-top: 1em;
    text-align: right;

    button { margin-right: 1.5em; }
  }
}

button, a.button {
  display: inline-block;
  font-size: 0.9em;
  font-family: inherit;
  padding: .5em 2em;
  border-radius: 1em;
  border: 1px solid transparent;
  text-transform: uppercase;
  text-decoration: none;
  color: #fff;
  background: #c6c6c6;
  cursor: pointer;
  white-space: nowrap;
  
  &.primary { background: #1997bc; }
  &.inverted { color: #1997bc; background: none; }
  &.inline { padding: 0 .5em; text-transform: none; }
  &.bordered { border: 1px solid #1997bc; border-radius: 5px; background: #fff; }
  &:hover, &:focus { text-decoration: underline; }

  i.fa { padding-right: .25em; }

  &[disabled] { cursor: not-allowed; opacity: 0.5; }
}

input[type=search] {
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  padding: 0.3em 0.6em;
  display: inline-block;
  font-size: 1.1em;
  font-family: inherit;
}
select {
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  padding: 0.3em 0.6em;
  display: inline-block;
  font-size: 1.1em;
  font-family: inherit;
}

/******
 * Nav styles
 */
nav {
  a { display: inline-block; text-decoration: none; 
      padding: 10px; margin: 10px; position: relative; color: #1d1d26;  
      border-bottom: 3px solid transparent; outline: 0; flex: 1;
    }
  a:hover, a:focus { border-bottom: 3px solid #1d1d26; }
  a.active { color: #1997bc; border-bottom: 3px solid #1997bc; }
}

a { color: #1997bc; }
a[disabled] { 
  color: #c3c3c3; 
  pointer-events: none;
}

.nowrap { white-space: nowrap; }

/******
 * Table styles
 */
table { 
  width: 100%;
  text-align: left;
  border: 2px solid #e7e7e7;
  border-collapse: collapse;
  white-space: nowrap;
}
th {
  font-size: .8em;
  border-bottom: 2px solid #e7e7e7;
  padding: 1em;
}
td {
  border-bottom: 1px solid #e7e7e7;
  padding: 1em;
  vertical-align: middle;
}
tr.slim td {
  padding: 0;
}
tr.is-inactive td {
  color: gray;
  font-style: italic;
}
tr.stripe td {
  background: #f4f4f4;
}
td:last-child{
  text-align: right;
}
th.slim {
  width: 0;
}
table.naked {
  border: none;
  th {
    border: none;
  }
  td {
    border: none;
  }
  td:last-child {
    text-align: left;
  }
}
td.no-results,
td.message {
  padding: 10px;
  text-align: center;
}

.expandable-details {
  overflow: hidden;
  height: 0;

  &.is-expanded {
    height: auto
  }

  .expandable-details-inner {
    padding: 1em;
  }
}

.badge {
  display: inline-block;
  background: #1d1d26;
  color: #fff;
  font-size: 0.7em;
  border-radius: 1em;
  padding: 0.25em;
  width: 2em;
  height: 2em;
  box-sizing: border-box;
  text-align: center;
}

.select-all {
  user-select: all;
}

.coming-soon {
  padding: 1em;
}

menu.expanding {
    padding: 0;
    margin: 0 0 0 .25em;
    position: relative;

    &::after {
      content: '\f078';
      font-family: 'FontAwesome';
      font-size: 0.8em;
      color: #56565d;
      margin-left: .5em;
      position: relative;
      z-index: 1;
    }

    & > span {
      cursor: default;
      position: relative;
      z-index: 1;
    }

    ul {
      display: none;
      position: absolute;
      right: -.5em;
      top: -.5em;
      margin: 0;
      min-width: 100%;
      padding: 2.7em 1em 1em 1em;
      border: 2px solid #e7e7e7;
      border-radius: 5px;
      background: #fff;
      list-style: none;
    }
    &:hover, &:focus-within {
      z-index: 2;
      ul { display: block; }
    }
}
.user-manager,
.lender-manager {
  menu.expanding {
    span {
      color: #fff
    }
    button {
      padding: .5em 0;
    }
    &:hover span {
      color: inherit;
    }
  }
}

.full-bleed {
  width: 100vw;
  margin-left: calc( -1 * ( 100vw - 910px ) / 2);
}

@media only screen and (max-width: 980px) {
  .full-bleed {
    width: 100%;
    margin-left: 0;
  }
}

@media print {
  body, html, #root {
      width: 100%;
      max-width: none;
      height: fit-content;
      overflow: visible;
      .App {
        width: 100%;
        max-width: none;
        height: fit-content;
        overflow: visible;
      }
      .MerchantReport .box {
        margin-top: 10px;
        // page-break-inside: avoid;
        // .feedback-entry, .expanding-row {
        //   page-break-inside: avoid;
        // }
      }
      // .MerchantReport .alternate-info tr {
      //   page-break-inside: avoid;
      // }
      .inquiry-navigator {
        display: none;
      }
      .print-button {
        display: none;
      }
  }
}
