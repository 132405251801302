.block-meter {
  padding: .5em 1em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  width: 100px;

  &.is-small {
    padding: .25em .5em;
    width: auto;
  }

  &.rank-unknown { background: #e7e7e7; color: gray; }
  &.rank-worst { background: #ED1B25; }
  &.rank-bad { background: #FF7F27; }
  &.rank-ok { background: #FED34F; }
  &.rank-good { background: #66DB84; }
  &.rank-best { background: #22B04C; }
}

.meter {
    flex: 5;
    display: flex;
    flex-direction: row;
    height: 3px;
    position: relative;

    .meter-value {
        flex: 1;
        margin-right: 2px;
    }

    &:after {
        content: ' ';
        display: block;
        background: #000;
        border-radius: 1em;
        position: absolute;
        left: var(--value);
        width: 0.6em;
        height: 0.6em;
        border: 2px solid #fff;
        top: -0.35em;
    }
}