.merchant-metadata{
    flex: 1;
    margin-top: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .section {
        width: 16em;
        margin-top: 0;
        overflow-wrap: break-word;
    }
}
