
  .h3NoTop{
    margin-top: 0px;

    img {
      width: 48px;
    }
  }

  .MerchantReport .box1 {
    border: 2px solid #e7e7e7;
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 1em;
  
    
}

.button{
    color: black;
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    overflow: hidden;
    outline: none;
    text-decoration: none;
    &:hover, &:focus { text-decoration: none; }
}

  .bRightUp {
    position: absolute;
    left: 50%;
    transform: translate(0%, -100%);
}

  .expanded{
    position: absolute;
    left: 50%;
    transform: translate(0%, -65%);
}

.slick-prev,
.slick-next {
  color: black !important; /* Force the color change */
  z-index: 1 !important;
}

.slick-prev:before,
.slick-next:before {
  color: black !important; /* Force the color change */
}

.carousel-div-container {
  margin: 0 auto; /* Center the carousel */

}
.slider-container {
  
  width:95%;
  margin: 0 auto; /* Center the carousel */

}

.slider-card{
  // display:flex;
  // flex-direction:row;
  border:1px grey solid;

  span{
    font-size:12px;
    margin:-0.5px;
    margin-left:2px;
    white-space:nowrap;
    // overflow:auto;
  
  }

  .rightIndent {
  position: absolute;
  left: 47%      
}
}

.slider-card-child{
  flex:1;  
}

.slideCounter{
    text-align: center;
    margin-right: 4%;
}


.blj-refresh-div{
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center;    /* Centers vertically */
  padding-top: 5px;
  // padding: 10px 10px;
  // border: 2px solid #007bff;
  // border-radius: 5px;
}

.blj-refresh{
  padding: 7px 10px; /* Example padding, adjust as needed */
}

.grid2{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  grid-template-areas:
    "a b c"
    "d e f"
    "g h i";
  align-items: start;
}

.grid2 div:nth-child(1) {
  grid-area: a;
}
.grid2 div:nth-child(2) {
  grid-area: b;
}
.grid2 div:nth-child(3) {
  grid-area: c;
}
.grid2 div:nth-child(4) {
  grid-area: d;
}
.grid2 div:nth-child(5) {
  grid-area: e;
}
.grid2 div:nth-child(6) {
  grid-area: f;
}
.grid2 div:nth-child(7) {
  grid-area: g;
}
.grid2 div:nth-child(8) {
  grid-area: h;
}
.grid2 div:nth-child(9) {
  grid-area: i;
}