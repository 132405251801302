@import '../../variables.scss';


.link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    text-transform: none;
    display: inline;
    margin: 0;
    padding: 0;
    i.fa { padding-left: .3em; }
    white-space: nowrap;
    color: #1997bc;
}
  
.link-button:hover,
.link-button:focus {
  text-decoration: none;
}

.loading-spinner {
    justify-content: left;
    vertical-align: middle;
    padding-right: 2px; 
}

.MerchantReport {
    font-size: 0.8em;

    header {
        display: flex;
        flex-direction: row;
        padding-top: .7em;
    }

    footer {
      padding: 1em;
      text-align: right;
    }

    h1 { font-size: 24px; }

    h2, span.upper { 
        text-transform: uppercase;
        font-size: 1em;
    }

    span.pad { 
        padding-left: 0.5em;
        padding-right: 0.5em;
    }

    h3 {
        background: #eff9ff;
        width: 100%;
        box-sizing: content-box;
        width: calc( 100% - 6px );
        margin-left: calc( -1em + 3px );
        padding: 1em;
    }

    nav { 
        display: flex; 
        flex-direction: row;
        align-items: baseline;
        white-space: nowrap;

        a{
            padding:8px;
            margin:8px;
        }

    }
    
    .overall-score { 
        min-width: 4em;
        padding: 1em;
        border-radius: 5px;
        align-self: baseline;
        text-align: center;
    }

    .box {
        border: 2px solid #e7e7e7;
        padding: 1em;
    }

    table {
        white-space: normal;
        border: 0;
        width: 100%;
        max-width: 100%;
        table-layout: fixed;

        .row-0{
            background: #f4f4f4;
        }
        td, th { border: 0 }
        td:last-child{
            text-align: left;
        }
    }

    table.ratings-by-source {
        table-layout: fixed;
        th:first-child { width: 150px; }
        th:last-child { width: 160px; }
    }

    .partnership-status {
        &::before {
            content: ' ';
            display: inline-block;
            margin-right: .5em;
            border-radius: 1em;
            height: .7em;
            width: .7em;
        }

        &.status-closed::before { background-color: #fc101b; }
        &.status-current:before { background-color: #6aa74f; }
        &.status-declined::before { background-color: #fc101b; }
        &.status-pending:before { background-color: #efd00a; }
        &.status-closed-partner:before { background-color: #efd00a; }
        &.status-closed-out-of-business:before { background-color: #efd00a; }


        em {
            font-style: normal;
            font-size: 0.8em;
        }
    }

    .coming-soon {
        padding: 2em;
        text-align: center;
        font-size: 2em;
        font-style: italic;
    }

    .association {
        border-bottom: 1px solid #c3c3c3;
        padding: 1em 0;
    }
    .association:first-child {
        padding-top: 0;
    }
    .association:last-child {
        border-bottom: none;
        padding-bottom: 0;
    }

    .box, .alternate-info {
        margin-bottom: 20px;
    }
    .report-date {
        display: block;
        padding-top: 10px;
    }
    .merchant-header-line {
        h1, a, button, em{
            display: inline-block;
            vertical-align: middle;
        }
        a, button, em {
            margin-left: 2em;
        }
    }
}

.score-line {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: .5em;
    font-size: .9em;

    .label { flex: 3; }
    .info { 
        flex: 2; 
        padding: 0 1em; 
        text-align: right;
        height: 1.5em;
    }

    .blank {
        height: 1em;
        &:after { display: none; }
        &:before {
            display: block;
            content: ' ';
            width: 100%;
            height: 3px;
            background: #c3c3c3;
            margin-top: 6px;
            position: relative;
        }
    }

}

table.addresses {
    margin: 1em;

    th { 
        border-bottom: #c3c3c3; 
        text-transform: uppercase;
    }
    td, th { padding: 0; }
}

table.service-summary {
    td.label {
        font-weight: bold;
        text-decoration: underline;
        vertical-align: top;
        width: 1px;
        white-space: nowrap;
    }
    tr.total-complaints td{
        padding-top: .25em;
        padding-bottom: .25em;
    }
}

table.complaints-by-source {
    i.fa { padding-left: .3em; }
    a { white-space: nowrap; }
}

.review-entry {
    position: relative;
    padding: 1em 200px 1em 0;
  
    .review-date,
    .review-name {
      display: block;
      font-weight: bold;
    }
  
    .review-text {
      padding-top: 1em;
      white-space: pre-line;
      word-break: break-word;
    }
  
    .review-rating {
      position: absolute;
      top: 1em;
      right: 0;
  
      .star {
        display: inline-block;
        margin-left: 4px;
      }
      .rating-text {
        display: none;
      }
    }
  }