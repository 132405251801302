
.Header {
  height: 80px;
  padding: 20px;
  border-bottom: 2px solid #e7e7e7;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #fff;
  position: relative;
  z-index: 2;
  min-width: 1100px;

  .badge {
      position: absolute;
      top: -5px;
      right: -10px;
  }
}

.Header-nav {
  flex: 1;
  ul { padding: 0; margin: 0; list-style: none; }
  li { list-style: none; display: inline-block; }
}

.Header-logo {
  pointer-events: none;
  width: 133px;
  margin-right: 2em;

  img {
    width: 100%;
  }
}

.Header-menu {
  position: relative;

  a, menu, span { display: inline-block; margin: 0; }

  menu {
    display: inline-flex;
    align-items: baseline;
  }

  .user-info {
    display: inline-block;
    padding-left: .5em;
  }

  .username, .lendername {
    display: block;
  }

  .lendername {
    font-size: 0.7em;
    font-weight: normal;
  }

  menu.expanding ul {
    padding-top: 3.7em;
  }
  ul.has-lender-switcher {
    width: 300px;
  }

  .field {
    padding-right: 0;
    display: block;
  }

  button {
    display: block;
    margin: 0 auto;
  }

}

.modal-overlay {
  width:100%;
  margin-top:8px;
  margin-bottom:8px;
  // background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.high-level-text{
  font-size:18px;
}

.scheduled-text{
  font-size:14px;
  text-align:center;
}

.upcoming-release-text{
  margin-top:0px;
  margin-bottom:-8px;
  font-size:24px;
  // align-items: center;
  text-align:center;
}

.view-release-details{
  margin-top:-8px;
  text-align:center;
  font-size:14px;
}


.modal-content {
  background: #ffffe0; /* light yellow background */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  width: 100%;
  text-align: left;
  position: relative;
}

.close-button {
  float: right;
  top: 10px;
  right: 10px;
  background: grey;
  border: none;
  font-size: 12px;
  cursor: pointer;
  justify-content:right;
  align-items:right;
}

.acknowledge-button {
  top: 10px;
  right: 10px;
  background: grey;
  border: none;
  font-size: 12px;
  cursor: pointer;
  justify-content:right;
  align-items:right;
}

.page-error {
  width: 400px;
  margin: 0 auto;
  color: #a94442;
  background: #f2dede;
  border: 1px solid #ebccd1;
  padding: .5em 1em;
  text-align: center;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;

  span {
    flex: 1;
  }

  .clear {
    color: #a94442;
    border: 1px solid transparent;
    cursor: pointer;
    background: none;
    padding: 0;
  }
}