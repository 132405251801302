.compliance-agreement {

  p {
    font-style: italic;
  }

  .checkbox-field {
    display: flex;
    flex-direction: row;
    align-items: baseline;

    input {
      transform: scale(1.5);
      cursor: pointer;
    }

    label {
      padding-left: .5em;
      font-weight: bold;
      cursor: pointer;
    }
  }
}