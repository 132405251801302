.onboarding-form {
  position: relative;

  form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .InquiryPrincipalForm {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    h1, footer {
      flex-basis: 100%;
    }

    .form-header {
      display: flex;
      align-items: flex-end; /* Align items to the bottom */
      justify-content: space-between;
      width: 98%;
      position: relative; /* Added to create a positioning context for the tooltip */
      

      .tooltip-container {
        display: flex;
        flex-direction: row;
        align-items: center; /* Align items vertically in the middle */
        max-width: 200px; /* Adjust the max-width as needed */
        white-space: nowrap; /* Prevent wrapping */
        transform: translateY(-15px);
        
        .tooltip {
          font-size: 0.8em; /* Reduce the font size */
          margin-left: 10px; /* Add some space between tooltips */
        }
      }
    }


    .field {
      flex-grow: 1;
      flex-shrink: 0;
    }

    .field-business-name {
      flex-basis: 60%
    }

    .field-fein {
      flex-basis: 40%
    }

    // .field-fein {
    //   flex-basis: 25%;
    // }

    .field-state {
      flex-basis: 5em;
    }

    // .field-state {
    //   flex-basis: 15%;
    //   transform: translateY(-2px);
    //   z-index: 1;
    // }

    .field-address {
      flex-basis: calc(100% - 30em)
    }

    .field-cty {
      flex-basis: 15em;
    }

    // .field-city {
    //   flex-basis: 15em;
    //   transform: translateY(-2px);
    // }

    .field-zip {
      flex-basis: 7em;
    }

    // .field-zip {
    //   flex-basis: 10em;
    //   transform: translateY(-2px);
    // }

    .field-alt-phone {
      flex-basis: 38.5%;
    }

    .field-domain-name {
      flex-basis: 38.5%;
    }



    .field-business-email,
    .field-business-phone,
    .field-domain {
      flex-basis: 33%;
    }

    .field-first-name,
    .field-middle-name,
    .field-last-name {
      flex-basis: 30%;
    }

    .field-name-suffix {
      flex-basis: 10%;
    }

    .field-store-id {
      flex-basis: 45%;
      flex-shrink: 0;
      flex-grow: 0.10;

    }
  }
}

// .select {
//   border: 1px solid #e7e7e7;
//   border-radius: 5px;

//   [class*="control"] {
//     border: None;
//     border-radius: None;
//     font-family: inherit;
//     height: 80%;
//   }

//   [class*="ValueContainer"] {
//     background-color: #f7fcff; 
//     height: 80%;
//   }

//   [class*="indicatorContainer"] {
//     background-color: #f7fcff; 
//   }
// }