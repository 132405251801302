
.merchant-service-feedback {
    padding: 0 1em;
  
    footer {
      padding-top: 1em;
      text-align: center;
    }
  
    button.load-more {
      background: none;
      border: none;
      text-decoration: underline;
      color: rgb(25, 151, 188);
      text-transform: none;
      padding: 0;
      margin: 0;
      font-size: 1em;
    }
  
    .service-url {
      display: flex;
      flex-direction: row;
      min-width: 0;
      white-space: nowrap;
  
      a {
        display: inline-block;
        padding: 0 1em;
        min-width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  
  .service-yelp {
    .star {
      height: 20px;
      width: 21px;
      background: url(../../../../assets/yelp-stars.png)
    }
    .star-off { background-position-x: 100%;}
  }
  .service-bbb {
    .star {
      height: 20px;
      width: 21px;
      background: url(../../../../assets/bbb-stars.png)
    }
    .star-off { background-position-x: 100%;}
  }
  .service-google {
    .star {
      height: 20px;
      width: 21px;
      background: url(../../../../assets/google-stars.png)
    }
    .star-off { background-position-x: 100%;}
  }
  .service-facebook {
    .rating-text { display: inline-block !important; }
    .rating-stars { display: none; }
  }
  
  .feedback-entry {
    position: relative;
    padding: 1em 200px 1em 0;
  
    .feedback-date,
    .feedback-name {
      display: block;
      font-weight: bold;
    }
  
    .feedback-text {
      padding-top: 1em;
      white-space: pre-line;
      word-break: break-word;
    }
  
    .feedback-rating {
      position: absolute;
      top: 1em;
      right: 0;
  
      .star {
        display: inline-block;
        margin-left: 4px;
      }
      .rating-text {
        display: none;
      }
    }
  }