
#error {
    circle {
        transform-origin: center;
        stroke: #ED1B25;
    }

    #x1 {
        stroke: #ED1B25;
        stroke-width: 10;
        transition: all 1s;
    }

    #x2 {
        stroke: #ED1B25;
        stroke-width: 10;
        transition: all 1s;
    }
}


#cancel {
    circle {
        transform-origin: center;
        stroke: #c3c3c3;
    }

    #x1 {
        stroke: #c3c3c3;
        stroke-width: 10;
        transition: all 1s;
    }

    #x2 {
        stroke: #c3c3c3;
        stroke-width: 10;
        transition: all 1s;
    }
}


#manual {
    circle {
        transform-origin: center;
        stroke: #c3c3c3;
    }

    #x1 {
        stroke: #c3c3c3;
        stroke-width: 10;
        transition: all 1s;
    }

    #x2 {
        stroke: #c3c3c3;
        stroke-width: 10;
        transition: all 1s;
    }
}


#done {
    circle {
        stroke: #63bc01;
        transform-origin: center;
    }

    #checkmark {
        stroke: #63bc01;
        stroke-width: 10;
        transition: all 1s;
    }
}

#loading {
    circle {
        stroke-dasharray: 250;
        stroke-dashoffset: 0;
        animation: load 2s linear infinite;
        transform-origin: center;
    }

    @keyframes load {
        0% {
            transform: rotate(0deg);
            stroke-dashoffset: 250;
        }
        50% {
            stroke-dashoffset: 0;
        }
        100% {
            transform: rotate(360deg);
            stroke-dashoffset: -250;
        }
    }
}

.ttText {
    pointer-events: none;
    color: black;
    font-size: 0.9em;
    font-family: inherit;
    font-weight: 500;
}
