.match-badge-container {
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0;
}

.match-badge-icon {
    font-size: 10px; /* Adjust font size as needed */
    font-weight: normal;
    transition: transform 0.2s; /* Add a hover effect */
}

.match-badge-icon::after{
    content: attr(data-title);
    position: absolute;
    bottom: -110%; /* Position above the icon */
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 4px 8px;
    border-radius: 4px;
    white-space: nowrap;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease;
    z-index: 1;
    font-size: 12px;
}

.match-badge-icon:hover {
    transform: scale(1.05);
    cursor: pointer;
}

.match-badge-icon:hover::after {
    opacity: 1; /* Show the tooltip on hover */
}