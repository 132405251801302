
.recent-reports-navigator {
  position: sticky;
  left: 0;
  width: 100%;
  background: #fff;
  z-index: 1;
  height: 38px;

  .recent-reports-navigator-content-wrap {
    max-width: 600px;
    margin: 0 auto 0 0;
    display: flex;
    flex-direction: row;

    & > div {
      display: inline-block;
      padding-right: 1em;
      position: relative;

      &:last-child {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-right: 0;
        min-width: 0;
      }
    }
  }

  .match-selector {
    display: inline-block;
    position: relative;
    margin-right: 1em;
    border: 1px solid #c6c6c6;
    flex: 1;
    padding: 0.3em 1em;
    cursor: default;
    min-width: 0;

    &::after {
      content: '‣';
      font-size: 2.8em;
      position: absolute;
      top: 0.4em;
      line-height: 0em;
      right: 3px;
      transform: rotate(90deg);
      color: #c6c6c6;
    }

    strong {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      display: inline-block;
    }

    ul {
      display: none;
      position: absolute;
      top: 100%;
      left: -1px;
      border: 1px solid #c3c3c3;
      padding: 0;
      margin: 0;
      width: calc(100% + 2px);
      background: #fff;
      max-height: 400px;
      overflow: auto;
    }

    &:hover ul {
      display: block;
    }

    &.collapse ul {
      display: none;
    }

    li {
      list-style: none;
      display: flex;
      flex-direction: row;
      align-items: center;

      &:hover {
        background: #eff9ff;
      }
    }

    a {
      padding: .5em 1em;
      text-decoration: none;
      display: block;
      flex: 1;
    }

    .viewed-note {
      color: gray;
      padding: 0 .5em;
      font-style: italic;
      font-size: .9em;
    }

    .address {
      font-weight: normal;
      color: #1d1d26;
      font-size: 0.8em;
      padding: 0 .5em;
    }

    .report-date {
      font-weight: normal;
      font-style: italic;
      color: #1d1d26;
      font-size: 0.8em;
    }
  }
}
