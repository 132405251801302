.lender-manager {
  .edit-lender-form {
    display: flex;
    flex-direction: row;
    align-items: top;
    text-align: left;
  }
  .feature-permissions-block {
    label {
      color: red;
    }
  }
  button {
    height: 3em;
  }
  .checkbox-group {
    background-color: #f7fcff;
    border: 1px solid #e7e7e7;
    border-radius: 5px;
    padding: 0.3em 0.6em;
    padding-bottom: 0.3em;

    input {
      display: inline-block;
      width: auto;
    }

    label {
      display: inline-block;
      width: auto;
    }
  }  
}