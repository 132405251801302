.scorecard-tab {
    /* Apply the following styles to the table */
    table {
        width: 100%;
    }

    th:nth-child(1), td:nth-child(1) {
        width: 58%;
        padding-right: 50px;
    }
    th:nth-child(2), td:nth-child(2) {
        width: 20%;
    }
    th:nth-child(3), td:nth-child(3) {
        width: 12%;
    }
    th:nth-child(4), td:nth-child(4) {
        width: 11%;
    }

    .note-button{
        padding: 0 .5em; 
        text-transform: none;
        color: #1997bc; 
        background: none;
    }

    .pass {
        color: green;
    }

    .fail {
        color:red;
    }

    .not-applicable {
        color:black;
    }

    .generate-scorecard-container {
        display: flex; 
        justify-content: center;
        align-items: center;
        align-content: center;
        button {
            font-size: 1.2em;
        }
    }

    .total-score th {
        font-size: 1em;
    }

    .scorecard-print button {
        font-size: 1em;
        float:'right';
        transform: 'translate(0%,-15%)';
    }
}

.rule-input-container {
    width: 100%;
}

.field{
    .rule-input-field {
        width: 50%;
    }
}
.rule-input-field {
    width: 50%;
}

.field-text-field {
    width: 50%; 
}

.rule-input-container{
    white-space: nowrap;
}

.rule-suggestion {
    font-size:0.8em;
    font-style:italic;
}

.rule-current-note-text {
    margin: 5px;
    position: absolute;
    top: 0;
    left: 0;
}

.rule-return-value-text{
    margin-top: 10px;
}

.rule-current-note-container{
    position: relative;
    background: #eeeeee;
    width: 855px; // max "page" width
    height: 107px;
    padding: 2em;
    border: 1px solid #666;
    border-radius: 5px;
    overflow: auto;
    overflow-wrap: break-word;
    word-break: break-all;
}


// overriding modal to display it in the middle of the screen
.scorecard-modal {
    .modal {
        position: fixed;
        z-index: 2;
        top: 80px; // header height
        right: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgba(255, 255, 255, 0.8);
        
        
        .modal-content {
            position: absolute;
            margin: auto;
            top: 25%;
            right: 0;
            bottom: 0;
            left: 0;
            width: 910px;

            background: #fff;
            max-width: 910px; // max "page" width
            max-height: 400px;
            overflow: auto;
            padding: 2em;
            margin: 20px auto;
            border: 1px solid #666;
            border-radius: 5px;
            box-shadow: 0 0 5px #666;
        }

        .input-span {
            display: flex;
            justify-content: space-between;
        }
        
        h1 {
            margin-top: 0;
        }

        h4 {
            display: flex;
            justify-content: space-between;
        }

        h4 > span {
            font-weight: 500;
        }
        
        footer {
            text-align: center;
        }
        }
        @media(max-width: 910px){
        .modal .modal-content {
            margin: 20px 10px;
        }
    }
}
