
  .h3NoTop{
    margin-top: 0px;

    img {
      width: 48px;
    }
  }

  .MerchantReport .box1 {
    border: 2px solid #e7e7e7;
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 1em;
  
    
}

.button{
    color: black;
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    overflow: hidden;
    outline: none;
    text-decoration: none;
    &:hover, &:focus { text-decoration: none; }
}

  .bRightUp {
    position: absolute;
    left: 50%;
    transform: translate(0%, -100%);
}

  .expanded{
    position: absolute;
    left: 50%;
    transform: translate(0%, -65%);
}


.grid1{
    display: grid;
    grid-template-columns: no-repeat(2, 40vw);
    grid-template-rows: repeat(1);
    grid-auto-rows: minmax(20px, auto);
    grid-auto-flow: column;
}

.slick-prev,
.slick-next {
  color: black !important; /* Force the color change */
  z-index: 1 !important;
}

.slick-prev:before,
.slick-next:before {
  color: black !important; /* Force the color change */
}

.carousel-div-container {
  margin: 0 auto; /* Center the carousel */

}
.slider-container {
  
  width:95%;
  margin: 0 auto; /* Center the carousel */
  // h3{
  //   width:100%,
  //   margin:
  // }
  // h2{
  //   text-align: center;
  // }

}

.slider-card{
  // display:flex;
  // flex-direction:row;
  border:1px grey solid;

  span{
    font-size:12px;
    margin:-0.5px;
    margin-left:2px;
    white-space:nowrap;
    // overflow:auto;
  
  }

  .rightIndent {
  position: absolute;
  left: 47%      
}
}

.slider-card-child{
  flex:1;  
}

.slideCounter{
    text-align: center;
    margin-right: 4%;
}



.grid1 div:nth-child(1) {
    grid-column: 1;
    grid-row: 1;
  }
  .grid1 div:nth-child(2) {
    grid-column: 2;
    grid-row: 1;
 }
 .grid1 div:nth-child(3) {
    grid-column: 1;
    grid-row: 2;
  }
  .grid1 div:nth-child(4) {
    grid-column: 2;
    grid-row: 2;
 }
