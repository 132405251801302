.deployment-detail-container {
    margin-top:-13px;
    align-items: left;
    text-align: left;
    text-wrap:wrap;
    width:122%;
    .high-level-text{
        font-size:16px;
    }
    
    .detailed-text{
        font-size:13px;
    }
  }

.show-detail-td{
    text-align: left;
    width:150%;
  }

.show-detail-button{
    font-size:12px;
    align-items:center;
}



.close-detail-button{
    font-size:12px;
    align-items:right;
    text-align:right;
    justify-content:right;
    float: right;
}

tr{
    border-bottom:1px;
}

table {
    border-collapse: collapse;
}