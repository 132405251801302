.Settings {

    header {
        display: flex;
        flex-direction: row;
        align-items: baseline;

        input[type=search] {
            margin-right: .5em;
        }
    }

    table {
        button {
            margin-left: 1em;
        }
    }

    .add-user-form {
        display: flex;
        flex-direction: row;

        .field { flex: 1 }
    }

    table.header-table {
        td:last-child{
            text-align: left;
        }        
    }

    .my-profile {
        input[type=number] {
            width: 140px;
            margin-right: 1em;
            line-height: 1.5em;
         }
    }
     
}