

.notifications-table {

    display: flex;
    flex-direction: column;
    justify-content: center;
  
    p {
      margin: 0;
      padding: 0 1.6em;
    }

    table {
        border: 1;
        padding: .25em 1em;
        margin-top:10px;
        min-width: 1100px;
        max-width: 1100px;
        vertical-align: middle;
        text-align: center;
        margin-left:50px;

      }
  
    td {
      border: 1;
      padding: .25em 1em;
      vertical-align: middle;
      text-align: center;
    }

    .user-table{
        table, td {
            border: 1;
            padding: .25em 1em;
            vertical-align: middle;
            text-align: left;
          }
        
        td:last-child {
            text-align:right;
        }
      
      }

    .notifs-container 
    {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

  
    th {
      cursor: default;
      user-select: none;
  
      &:last-child {
        cursor: default;
        text-align: right;
      }
  
      a.button {
        padding: 0;
        display: inline-block;
        text-align: center;
        width: 100%;
      }
    }
  
    h1, thead th {
      background: #fff;
    }
  
    header {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      padding: 2em;
      justify-content: center;
    }
  
    h1 {
      margin: 0;
    }
  
    .risk-filter {
      display: flex;
      align-items: baseline;
  
      input[type=checkbox] {
        margin-left: 5px;
      }
    }
  
    thead th {
      position: sticky;
      top: 0;
    }
  
    button {
      padding-top: .1em;
      padding-bottom: .1em;
    }
  
    .pager-more {
      padding: 1em;
      text-align: center;
  
      p {
        margin-bottom: .5em;
      }
    }
}

// work here bruh please
.notif-container-scrollable {
  td:nth-last-child(2) {
    white-space: pre-wrap;
  }

  td:first-child {
    white-space: pre-wrap;
  }

  height: 500px;
  // width:fit-content;
  display:flex;
  justify-content: center;
  .notif-table {
      thead {
          z-index: 15;
      }

      th {
          padding-bottom:12px;
          background-color: white;
          position: sticky;
          top: 0;
      }
  }
}

.notif-container {
  td:nth-last-child(2) {
    white-space: pre-wrap;
  }

  td:first-child {
    white-space: pre-wrap;
  }

  display:flex;
  justify-content: center;
  .notif-table {
      thead {
          z-index: 15;
      }

      th {
          padding-bottom:12px;
          background-color: white;
          position: sticky;
          top: 0;
      }
  }
}


  
.merchant-search {
    input {
      float: right;
      margin: 1em 0;
    }
  }

.link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    text-transform: none;
    display: inline;
    margin-left: 3em;
    padding: 0;
    i.fa { padding-left: .3em; }
    white-space: nowrap;
    color: #1997bc;
    
}

.view-past-notifs {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  text-transform: none;
  display: inline;
  padding: 0;
  i.fa { padding-left: .3em; }
  white-space: nowrap;
  color: #1997bc;
  
}

.view-past-notifs:hover,
.view-past-notifs:focus {
  text-decoration: none;
} 

.download-all {
  align-content:center;
  align-items: center;
  justify-content: center;
  display:flex;
}
  
.link-button:hover,
.link-button:focus {
  text-decoration: none;
} 

.round-meter {
    padding: 0;
    margin-left:5px;
    display: inline-flex;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    color: #fff;
    width: 100px;
    border-radius: 10px;
  
    &.is-small {
      padding: .25em .5em;
      width: auto;
    }
  
    &.rank-unknown { background: #e7e7e7; color: gray; }
    &.rank-worst { background: #ED1B25; }
    &.rank-bad { background: #FF7F27; }
    &.rank-ok { background: #FED34F; }
    &.rank-good { background: #66DB84; }
    &.rank-best { background: #22B04C; }
  }

  

  .centered-image{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

.sorter {
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
  color: #c6c6c6;
  margin-right: 3px;

  .active {
    color: black;
  }

  .sorter-up {
    position: absolute;
    top: -.05em;
  }

  .sorter-dn {
    position: absolute;
    bottom: -.50em;
  }
}

.notifications-filter {
    min-width: 1100px;
    max-width: 1100px;
    text-align: center;
}

.notifications-filter-closures {
  display: flex;
  min-width: 1100px;
  max-width: 1100px;
  margin-right:20px;
}