.user-manager {
  .flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  th input, th select {
    flex: 1;
    width: auto;
    margin-left: 1em;
    max-width: 200px;
  }

  th select option {
    color: #000;
  }

  .edit-user-form {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: left;
  }

  .edit-user-note {
    font-weight: bold;
    padding-bottom: 1em;
  }
  
  .admin-note {
    font-style: italic;
    padding-top: 1em;
  }
}