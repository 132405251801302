.loading {
  padding: 10px;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.lds-grid {
  margin-right: .5rem;
  display: inline-block;
  position: relative;
  font-size: 3px;
  width: 10em;
  height: 10em;
}
.lds-grid div {
  position: absolute;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  background: #089447;
  animation: lds-grid 1.2s linear infinite;
}
.lds-grid div:nth-child(1) {
  top: 1em;
  left: 1em;
  animation-delay: 0s;
}
.lds-grid div:nth-child(2) {
  top: 1em;
  left: 4em;
  animation-delay: -0.4s;
}
.lds-grid div:nth-child(3) {
  top: 1em;
  left: 7em;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(4) {
  top: 4em;
  left: 1em;
  animation-delay: -0.4s;
}
.lds-grid div:nth-child(5) {
  top: 4em;
  left: 4em;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(6) {
  top: 4em;
  left: 7em;
  animation-delay: -1.2s;
}
.lds-grid div:nth-child(7) {
  top: 7em;
  left: 1em;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(8) {
  top: 7em;
  left: 4em;
  animation-delay: -1.2s;
}
.lds-grid div:nth-child(9) {
  top: 7em;
  left: 7em;
  animation-delay: -1.6s;
}
@keyframes lds-grid {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
