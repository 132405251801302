$score-very-bad: #fc101b;
$score-bad: #fd9249;
$score-ok: #fed34f;
$score-good: #56db74;
$score-very-good: #6aa74f;

.score-very-bad { background: $score-very-bad; color: #fff }
.score-bad { background: $score-bad; color: #fff }
.score-ok { background: $score-ok; color: #000; }
.score-good { background: $score-good; color: #fff }
.score-very-good { background: $score-very-good; color: #fff }