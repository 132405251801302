.SelectMerchant {
    .header-with-button {
        display: flex;
        justify-content: space-between;
        align-items: center; /* Optional: align items vertically centered */
    }

    .header-with-button button{
        background: #1997bc;
    }

    h3 {
        background: #eff9ff;
        width: 100%;
        box-sizing: content-box;
        width: calc( 100% - 6px );
        margin-left: calc( -1em + 3px );
        padding: 1em;
    }
    
    .match-group {
        border: 2px solid #e7e7e7;
        margin-bottom: 1em;

        header, .merchant { padding: 1em; }

        header { border-bottom: 2px solid #e7e7e7; }
    }

    .merchant {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        border-bottom: 2px solid #e7e7e7;  
        overflow-wrap: break-word;

        &:last-child {
            border-bottom: 0;
        }

        h2 { 
            flex-basis: 100%;
            font-size: 1em;
            margin: 0;
            padding: 0;
        }
        p { 
            margin: 0; 
            font-size: 0.9em;
            width: 20%;
        }
        /* Styling for the first <p> tag */
        p:first-of-type {
            width: 40%; /* Custom width for the first <p> tag */
        }
    }

    .see-more {
        margin-bottom: 1em;
    }


    // overriding modal to display it in the middle of the screen
.merchant-request-modal {
    .modal {
        position: fixed;
        z-index: 2;
        top: 80px; // header height
        right: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgba(255, 255, 255, 0.8);
        
        
      .modal-content {
          position: absolute;
          margin: auto;
          top: 15%;
          right: 0;
          bottom: 0;
          left: 0;
          width: 910px;
          height: 420px;
  
          background: #fff;
          max-width: 910px; // max "page" width
          overflow: auto;
          padding: 2em;
          margin: 20px auto;
          border: 1px solid #666;
          border-radius: 5px;
          box-shadow: 0 0 5px #666;
      }
    }
  }


}

