
input[type=number] {
        width: 10%;
        margin-right:1em;
        line-height:1.5em;
    }
.scorecard-manager {    

    td {
        font-size:15px;
        white-space: pre-line;

    }
    .table-container {
        display: flex;
        justify-content: center;
      }
      
      th input, th select {
        flex: 1;
        width: auto;
        margin-left: 1em;
        max-width: 200px;
      }

      
      .rule-text-input-field{
        width: 300px;
      }
    
      .field-text-field {
       width: 50%; 
      }
    
      .edit-rule-form {
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: left;
        width: 180%;
      }

    //   .warning-message{
    //     // border: 1px solid #000;
    //     padding-left: 40px;
    //     padding-right:10px;
    //     margin-bottom:5px;
    //   }


      .edit-rule-td{
        border-bottom:none;
        text-align: left;
      }

      .rule-suggestion {
        font-size:0.8em;
        font-style:italic;
      }

    //   #edit-rule-text-field {
    //     width: 100%;
    //   }
    //   #edit-rule-pass-field {
    //     width: 100%;
    //   }
    //   #edit-rule-fail-field {
    //     width: 100%;
    //   }


      .rule-text-field {
        width: 55%;
      }

      .add-rule-text-div {
        width:100%;
      }
      
      .edit-rule-text-field {
        width:30%;
      }

      .rule-pass-field {
        width:10%;
      }

      .rule-fail-field {
        width:10%;
      }    
    
}

// overriding modal to display it in the middle of the screen
.scorecard-manager-modal{
  .modal {
    position: fixed;
    z-index: 2;
    top: 80px; // header height
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 0.8);


    .modal-content {
        position: absolute;
        margin: auto;
        top: 25%;
        right: 0;
        bottom: 0;
        left: 0;
        width: 910px;

        background: #fff;
        max-width: 910px; // max "page" width
        max-height: 200px;
        overflow: auto;
        padding: 2em;
        margin: 20px auto;
        border: 1px solid #666;
        border-radius: 5px;
        box-shadow: 0 0 5px #666;
    }


    h1 {
      margin-top: 0;
    }
  }
  @media(max-width: 910px){
    .modal .modal-content {
      margin: 20px 10px;
    }
  }
}
