.score-info {
    flex: 1;
    margin-top: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

  .score-display {
    border-radius: 18px;
    padding-top: 14px;
    width: 90px;
    height: 90px;
    text-align: center;
    font-size: 3em;
    font-weight: bold;
    color: #FFFFFF;

      &.rank-score-unknown { background: #e7e7e7; color: gray; }
      &.rank-score-highest { background: #ED1B25; }
      &.rank-score-high { background: #FF7F27; }
      &.rank-score-elevated { background: #FED34F;  color: #000000; }
      &.rank-score-lowest { background: #22B04C; }
  }

  .score-display-details {
    img {
      width: 48px;
    }

    .more-information {

      &::before {
        content: '\f05a';
        font-family: 'FontAwesome';
        color: #1997BC;
        padding-right: 4px;
        z-index: 1;
      }
    }

    padding-left: 6px;
    font-size: .9em;
  }
}