.health-check-page {

  header {
    text-align: right;
  }

  h2 {
    font-size: 1em;
    text-align: left;
  }

  p {
    font-size: 0.9em;
    line-height: 1.7em;
    padding: 0 1em;
  }

  .pager-more {
    text-align: center;
  }

  table.merchants-risk {
    margin: 2em 0;
    position: relative;

    h1 {
      margin: 0;
      padding: 0;
      font-weight: bold;
    }

    a.button {
      position: absolute;
      bottom: 1em;
      right: 1em;
    }

    th {
      vertical-align: bottom;
    }
    img {
      width: 48px;
    }

    th:first-child {
      width: 300px;
    }

    td {
      border: none;
    }

    th:nth-child(2),
    td:nth-child(2),
    th:nth-child(3),
    td:nth-child(3){
      text-align: right;
    }

    td:last-child {
      text-align: left;
    }
  }
}

.merchants-by-risk-score {

  p {
    margin: 0;
    padding: 0 1.6em;
  }

  table, td {
    border: 0;
    padding: .25em 1em;
    vertical-align: middle;
  }

  th {
    cursor: pointer;
    user-select: none;

    &:last-child {
      cursor: default;
      text-align: right;
    }

    a.button {
      padding: 0;
      display: inline-block;
      text-align: center;
      width: 100%;
    }
  }

  h1, thead th {
    background: #fff;
  }

  header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    padding: 1em;
  }

  h1 {
    margin: 0;
  }

  .risk-filter {
    display: flex;
    align-items: baseline;

    input[type=checkbox] {
      margin-left: 5px;
    }
  }

  thead th {
    position: sticky;
    top: 0;
  }

  button {
    padding-top: .1em;
    padding-bottom: .1em;
  }

  .pager-more {
    padding: 1em;
    text-align: center;

    p {
      margin-bottom: .5em;
    }
  }

  .sorter {
    position: relative;
    display: inline-block;
    width: 1em;
    height: 1em;
    color: #c6c6c6;

    .active {
      color: black;
    }

    .sorter-up {
      position: absolute;
      top: -.25em;
    }

    .sorter-dn {
      position: absolute;
      bottom: -.25em;
    }
  }
}

.merchant-search {
  input {
    float: right;
    margin: 1em 0;
  }
}

.portfolio-navigator {
  display: flex;
  justify-content: space-between;
  padding: 0 1em;

  label {
    background: #c6c6c6;
    padding: .5em 1em;
    margin: 0;
    border-radius: 5px 0 0 5px;
    color: #fff;
    font-size: 0.9em;
  }
  label + input {
    border-radius: 0 5px 5px 0;
  }
}
