.alternate-info {
    table {
        table-layout: auto !important;

        a {
            display: inline-block;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .merchant-metadata p {
        width: auto;
        margin-top: 0;
        max-width: 25em;
    }

    .dates {
        white-space: nowrap;

        strong {
            display: inline-block;
            width: 8em;
        }
    }

}
