.header {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Space between text and button */
    box-sizing: border-box;
    margin-bottom:-1px;

    .doctor-name {
        width: 40%;
        font-size:16px;
    }
}

.add-doctor-div{
    margin-top:3px;
    display:flex;
    align-items: center;
    justify-content: center;
    border-top: 1px dashed #7b7777;

    button {
        margin-top: 13px;
        font-size: 15px;
    }
}

.doctor-header {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Space between text and button */
    box-sizing: border-box;
    margin-top:-5px;
    margin-bottom:-15px;

    .doctor-name {
        width: 33%;
        font-size:16px;
    }

    .dropdown {
        font-size:15px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        text-transform: none;
        display: inline;
        margin: 0;
        padding: 0;
        i.fa { padding-left: .3em; }
        white-space: nowrap;
        color: #1997bc;
    }
    .dropdown:hover {
        text-decoration: underline;
    }
    
    .dropdown i.fa {
        padding-left: .3em;
    }
}

.doctor{
    border-radius: 5px; /* Rounded corners */
    padding: 5px;
}

.doctor:nth-child(odd) {
    background-color: #f0f0f0; /* Light grey background */
}

.doctor-licenses-container{

    .doctor-licenses-details{
        // border-top:1px solid #ccc;
        // padding-bottom: 1px;
        // margin-top:5px;
        // margin-bottom:5px;
    
        display: flex;
        align-items: center;
        justify-content: space-between; /* Space between text and button */
        box-sizing: border-box;
        border-bottom: 1px dashed #000000;
        padding: 15px;
    
        .item {
            display: flex;
            flex-direction: column; /* Stack spans vertically */
            justify-content: space-between; /* Distribute space evenly */
            flex: 1; /* Allow items to grow evenly */
            margin: 10px; /* Optional: Add some margin between items */
            width: 33%;

        }
        .blur-container {
            position: relative;
            overflow: hidden; /* Ensures the blur doesn't overflow */
        }
        
        .blurred {
            filter: blur(5px); /* Adjust blur amount as needed */
        }

        .header-button {
            font-size: 1em;
            background: #1997bc;
        }

    }

    .no-border {
        border-bottom: 0px;
        padding-bottom: 0px;
    }

    .add-license-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between; /* Space between text and button */
        box-sizing: border-box;
        padding: 15px;
        padding-top: 0px;
        padding-bottom: 0px;

        .button-container {
            display: flex;              /* Use flexbox */
            flex-direction: column;     /* Stack items vertically */
            align-items: center;        /* Center items horizontally */
            text-align: center;         /* Center text inside the h2 elements */
            flex-basis: 100%;
            
            button{
                margin-top: 10px;
                font-size: 1em;
                background-color: #1997bc;
              }

            .message {
                font-size: 14px;
                text-align: center; /* Center the text inside the span */
            }
        }

        .items {
            display: flex;
            flex-direction: column; /* Stack the header text vertically */
            justify-content: center; /* Align items to the start */
            flex: 1; /* Allow items to grow evenly */

            .message {
                font-size: 14px;
                text-align: center; /* Center the text inside the span */
            }

            .inputs-container {
                display: flex; /* Use flex to align inputs and button horizontally */
                justify-content: space-around;
                align-items: center; /* Center items vertically */
                gap: 10px; /* Space between inputs and button */

                input{
                    background-color: #f7fcff;
                    border: 1px solid #e7e7e7;
                    border-radius: 5px;
                    padding: 0.3em 0.6em;
                    display: block;
                    font-size: 1.3em;
                    font-family: inherit;
                    width: 100%;
                }

                label{
                    font-weight: bold;
                    font-size: 1em;
                    padding-bottom: 0.4em;
                    display: block;
                }
            }

            button{
                transform: translate(0%, 30%);
                font-size: 1em;
                background-color: #1997bc;
              }
        }
    }
    
    

    .license-flex{
        display: inline-flex;
      }
      
    .blur-text-container{
        position: relative;

        .overlay-text {
            position: absolute;
            top: 35%;
            left: 8%;
            width: 100%;
            height: 100%;
        }
    }

    .doctor-licenses-details:last-child {
        border-bottom: 0;
    }
}




// overriding modal to display it in the middle of the screen
.doctor-submission-modal {
    .modal {
        position: fixed;
        z-index: 2;
        top: 80px; // header height
        right: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgba(255, 255, 255, 0.8);
        
        
      .modal-content {
          position: absolute;
          margin: auto;
          top: 5%;
          right: 0;
          bottom: 0;
          left: 0;
          width: 910px;
          height: 620px;
  
          background: #fff;
          max-width: 910px; // max "page" width
          overflow: auto;
          padding: 2em;
          margin: 20px auto;
          border: 1px solid #666;
          border-radius: 5px;
          box-shadow: 0 0 5px #666;
      }
  
      .modal-header-button {
          display: flex;
          justify-content: space-between;
          align-items: center; /* Optional: align items vertically centered */
          font-size: 1.25em;
      }
        .doctor-submission-form {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            font-size: 1.25em;

            h1, footer {
                flex-basis: 100%;
            }

            .field {
                flex-grow: 1;
                flex-shrink: 0;
            }

            .field-license-name {
                flex-basis: 28%
            }

            .field-npi-id{
                flex-basis: 16%
            }

            .field-license-addr {
                flex-basis: calc(100% - 30em)
            }

            .field-street-address {
                flex-basis: 20%;
            }

            .field-street-address-number {
                flex-basis: 15%;
            }

            .field-street-address-state {
                flex-basis: 5%;
            }

            .field-street-address-zip {
                flex-basis: 10%;
            }
        }

        .npi-doctor-container {
            position: absolute;
            top: 300px; /* Adjust this based on the height of the form above it */
            bottom: 20px; /* Keeps the container above the modal footer */
            left: 20px;
            right: 20px;
            border: 1px solid black; /* For verification */
            border-radius: 5px;
            overflow-y: auto; /* Adds scroll if content exceeds the height */
        }
        
        .npi-doctor-card {
            display: flex;
            flex-direction: column; /* Stack content vertically */
            background-color: white;
            border: 1px solid #ccc;
            border-radius: 8px;
            padding: 15px;
            margin: 15px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            transition: box-shadow 0.3s ease;
        }
        
        .npi-doctor-card:hover {
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
        }
        
        /* New Flex Container for Doctor Info and Address */
        .doctor-content {
            display: flex;
            justify-content: space-between; /* Space between doctor info and address */
            margin-bottom: 10px; /* Space below the content */
        }
        
        .doctor-info {
            margin-right: 15px; /* Space between info and address */
        }
        
        .doctor-address {
            min-width: 150px; /* Ensure cards are not too narrow */
        }

        .doctor-overall-similarity {
            align-items: center; /* Center the badge vertically */
        }

        .similarity-badge {
            margin-left: 20px;
            margin-right: 20px;
            width: 100px; /* Width of the badge */
            height: 100px; /* Height of the badge */
            display: flex;
            align-items: center; /* Center text vertically */
            justify-content: center; /* Center text horizontally */
            border-radius: 10px; /* Rounded corners */
            color: white; /* Text color */
            font-weight: bold; /* Make the score stand out */
            font-size: 40px;
            position: relative; /* Added for tooltip positioning */
        }

        .similarity-badge::after {
            content: attr(data-title);
            font-weight: normal;
            position: absolute;
            bottom: 100%; /* Position above the badge */
            left: 50%; /* Center the tooltip */
            transform: translate(-50%, 400%); /* Center and adjust position */
            background-color: #333;
            color: #fff;
            padding: 4px 8px;
            border-radius: 4px;
            white-space: nowrap;
            opacity: 0; /* Start hidden */
            pointer-events: none; /* Prevent pointer events */
            transition: opacity 0.2s ease;
            z-index: 1;
            font-size: 12px;
        }
        
        .similarity-badge:hover {
            transform: scale(1.05);
            cursor: pointer;
        }
        
        .similarity-badge:hover::after {
            opacity: 1; /* Show the tooltip on hover */
        }
        
        /* Color coding classes */
        .high-similarity {
            background-color: green; /* Green for high similarity */
        }

        .medium-similarity {
            background-color: gold; /* Orange for medium similarity */
        }
        
        .low-similarity {
            background-color: orange; /* Orange for medium similarity */
        }

        .neutral-similarity {
            background-color: gray;
        }
        
        .bad-similarity {
            background-color: red; /* Red for low similarity */
        }
        
        /* Similarity section styling */
        .doctor-similarity {
            display: flex;
            flex-wrap: wrap; /* Wrap badges to the next line if needed */
            justify-content: flex-start; /* Align badges to the start */
        }
        
        .npi-doctor-card h2 {
            margin-top: 0;
            font-size: 1.2em;
        }
        
        .npi-doctor-card p {
            margin: 5px 0;
            font-size: 0.9em;
        }
    }
}

// overriding modal to display it in the middle of the screen
.doctor-confirmation-modal {
    .modal {
        position: fixed;
        z-index: 2;
        top: 80px; // header height
        right: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgba(255, 255, 255, 0.0);
        
        
      .modal-content {
          position: absolute;
          margin: auto;
          top: 25%;
          right: 0;
          bottom: 0;
          left: 0;
          width: 400px;
          height: 200px;
  
          background: #fff;
          max-width: 910px; // max "page" width
          overflow: auto;
          padding: 2em;
          padding-bottom: 1em;
          margin: 20px auto;
          border: 1px solid #666;
          border-radius: 5px;
          box-shadow: 0 0 5px #666;
      }
  
      .button-container{
        display:flex;
        justify-content: space-around;
        margin-bottom: 0px;
      }

      button{
        font-size: 1.1em;
      }
    
    }
}


.doctor-review-details{
    .dropdown {
        font-size:12px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        text-transform: none;
        display: inline;
        margin: 0;
        padding: 0;
        i.fa { padding-left: .3em; }
        white-space: nowrap;
        color: #1997bc;
    }
    .dropdown:hover {
        text-decoration: underline;
    }
    
    .dropdown i.fa {
        padding-left: .3em;
    }

    .right-border {
        border-right: 1px dashed black;
    }

    .top-border {
        border-top: 1px dashed black;
    }
}



