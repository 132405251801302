.csv-link {
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    text-transform: none;
    display: inline;
    padding: 0;
    margin-left: 0em;
    i.fa { padding-left: .3em; }
    white-space: nowrap;
    color: #1997bc;
}