.score-gauge {
  flex: 1;
  margin-top: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .score-gauge-header {
    width: 20em;
    font-size: 1.2em;
    font-weight: bold;
    text-transform: uppercase;
    padding-top: 20px;
  }

  .score-gauge-reasons-header {
    width: 20em;
    font-size: 1.2em;
    font-weight: bold;
    text-transform: uppercase;
    padding-top: 20px;
  }

  .score-gauge-details {

    .score-gauge-scorebar-container {
      margin-top: -16px;
    }

    .score-gauge-default-score-div{
      display: flex;
      justify-content: center; /* Centers content horizontally */
      align-items: center; /* Centers content vertically */
      width: 100%; /* Makes the div the same width as the parent */
      font-size: 1.2em;
      font-weight: bold;
      text-transform: uppercase;
      margin-right: 30px;
      margin-bottom: 10px;
      position: relative;
      color: gray;
    }

    .score-gauge-score {
      font-size: 1.2em;
      font-weight: bold;
      text-transform: uppercase;
      margin-right: 30px;
      position: relative;
    }

    .score-gauge-needle {
      border-radius: 4px;
      width: 8px;
      height: 16px;
      background-color: #000000;
      position: relative;
      top: 3px;
    }

    .score-gauge-scorebar {
      width: 120px;
      height: 8px;
      display: inline-block;
      margin: 0 2px;

      &.lowest {
        background: #22B04C;
        width: 130px;
      }

      &.elevated {
        background: #FED34F;
        width: 130px;
      }

      &.high {
        background: #FF7F27;
        width: 130px;
      }

      &.highest {
        background: #ED1B25;
        width: 130px;
      }
    }

    .score-gauge-scoredesc {
      width: 120px;
      height: 21px;
      display: inline-block;
      margin: 0 2px;
      color: #FFFFFF;
      margin-top: 4px;
      padding-top: 2px;
      font-size: .9em;
      text-align: center;

      &.lowest {
        background: #22B04C;
        width: 130px;
      }

      &.elevated {
        background: #FED34F;
        width: 130px;
      }

      &.high {
        background: #FF7F27;
        width: 130px;
      }

      &.highest {
        background: #ED1B25;
        width: 130px;
      }
    }
  }

  .score-gauge-reasons {
    width: 536px;
    margin-top: 15px;
  }

  .score-gauge-reason-codes {
    display: flex;
    justify-content: center; /* Centers content horizontally */
    align-items: center; /* Centers content vertically */
    width: 100%; /* Makes the div the same width as the parent */
    font-size: 1em;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
  }
}