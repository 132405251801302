
.merchant-complaint-detail {
  padding: 0 1em;

  footer {
    padding-top: 1em;
    text-align: center;
  }

  button.load-more {
    background: none;
    border: none;
    text-decoration: underline;
    color: rgb(25, 151, 188);
    text-transform: none;
    padding: 0;
    margin: 0;
    font-size: 1em;
  }

  table tr td {
      vertical-align: top;
      white-space: pre-line;      
  }

  .feedback-entry {
    position: relative;
    padding: 1em 200px 1em 0;
  
    .feedback-date,
    .feedback-name {
      display: block;
      font-weight: bold;
    }
  
    .feedback-text {
      padding-top: 1em;
    }
  }

}

