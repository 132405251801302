.MerchantReport {

    .performance-table-header {
        font-weight: bold;
        font-size: 105%;
        margin-top: 15px;
        margin-bottom: 5px;
    }

    .performance-table-footer {
        font-style: italic;
    }

    .performance-table {
        border: solid thin;

        table {
            table-layout: fixed;
    
            td:last-child, td:first-child {
                text-align: center;
            }
            
            th, td {
                padding-left: 0.5em;
                padding-right: 0.5em;
                padding-top: 1px;
                padding-top: 1px;
                border-left: solid thin;
            }

            th:first-child, td:first-child {
                border-left: none;
            }  

            .performance-head-group {
                text-align: center;
                vertical-align: top;
            }

            .performance-sub-head-group-line {
                border-bottom-width: thin;
                border-bottom-style: solid;
            }

            .performance-sub-head-group {
                border-bottom-width: thin;
                border-bottom-style: solid;

                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                vertical-align: bottom;
        
                .performance-sub-head {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    text-align: center;
                    font-size: 85%;
                    flex-basis: 100%;
                }

                .performance-sub-sub-head {
                    text-align: center;
                    flex-basis: 45%;
                }

                .performance-sub-sub-head-sep {
                    flex-basis: 10%;
                }
            }
            
            .performance-data-column {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
            }
            .performance-data-group {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                flex-basis: 100%;
            }
            .performance-data-value {
                text-align: center;
                flex-basis: 45%;
                font-size: 90%;
            }
            .performance-data-value-sep {
                text-align: center;
                flex-basis: 10%;
                font-size: 90%;
            }


        }
    }
    .table-scrollable {
        height: 400px;
        overflow:auto;

        .inquiry-table {
            thead {
                z-index: 15;
            }
    
            th {
                padding-bottom:12px;
                background-color: white;
                position: sticky;
                top: 0;
            }
        }
    }
    .table-scrollable-short {
        overflow:auto;
        .inquiry-table {
            thead {
                z-index: 15;
            }
    
            th {
                padding-bottom:12px;
                background-color: white;
                position: sticky;
                top: 0;
            }
        }
    }
    .table-printable {
        overflow:none;
    }


}
