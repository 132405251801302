

.regulatory-search {

    .regulatory-table {
        table-layout:fixed;
        margin-top:5px;
        
        border-collapse: collapse;
        margin: 10px 0;
        font-family: sans-serif;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    
        thead tr {
            background-color: #000061;
            color: #ffffff;
            text-align: left;
        }
    
        .row-0{
            background: #f4f4f4;
        }
    
        tbody tr:last-of-type {
            border-bottom: 2px solid  #000061;
        }
    
    
    
        th:nth-child(1), td:nth-child(1) {
            width: 14%;
        }
        th:nth-child(2), td:nth-child(2) {
            width: 25%;
        }
    
        th:nth-child(3), td:nth-child(4) {
            width: 20%;
        }
    
        th:nth-child(4), td:nth-child(4) {
            text-align:left;
        }
    
    
    
    
        td {
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: normal;
            font-size: 14px;
        }
        td:last-child{
            text-align:left;
        }
    
    }
    
    .centered-nav {
        align-content:center;
        align-items: center;
        justify-content: center;
        display:flex;
        gap:10px;
    }
    
    h2 {
        font-size: 35px;
        font-weight: 600;
        background-image: linear-gradient(to left, #000061, #000061);
        color: transparent;
        background-clip: text;
        -webkit-background-clip: text;
        align-content: center;
        justify-content: center;
        display:flex;
        margin: 2px;
        
      }
    
    
    
    h3 .reg-search-title{
        font-family: georgia-serif;
        background: #eff9ff;
        width: 100%;
        box-sizing: content-box;
        width: calc( 100% - 6px );
        margin-left: calc( -1em + 3px );
        padding: 1em;
        align-content: center;
        justify-content: center;
        display:flex;
    }
    
    .link-button {
        background-color: transparent;
        border: none;
        cursor: pointer;
        text-decoration: underline;
        text-transform: none;
        display: inline;
        margin: 0;
        padding: 0;
        i.fa { padding-left: .3em; }
        white-space: nowrap;
        color: #1997bc;
    }
      
    .link-button:hover,
    .link-button:focus {
      text-decoration: none;
    }
    
    .right-col th{
        text-align:right;
    }
    
    p {
        font-size: 14px;
        margin:0;
    }
    
    .filters{
        text-align: right;
    }
    
    hr.style-two {
        border: 0;
        height: 1px;
        background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
    }
}
