
.h3NoTop{
  margin-top: 0px;

  img {
    width: 48px;
  }
}

.MerchantReport .box1 {
  border: 2px solid #e7e7e7;
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 1em;
  
    
}

.bRight {
    position: absolute;
    left: 50%      
}

.online-presence-date {
  position: absolute;
  left: 33%      
}

.online-presence-column2 {
  position: absolute;
  left: 56%      
}

.online-presence {
  display:flex;
}

.online-presence-child {
  flex:1;
}




.bLeft {
  position: absolute;
  left: 0;
  max-width: 48%;
  max-height: 19px;
  overflow: hidden;
  word-break: break-all;
}

.outer {
  position: relative;
  width: 100%;
}

.license-links {
  p {
    margin: 0;
  }
}

.override-loading-spinner {
  position: relative;
  padding-right: 2px; 

  .ttText{
    font-size: 0.8em;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Space between text and button */
  box-sizing: border-box;
}

/* Style for the button to make it smaller */
.header-button {
  font-size: 0.8em;
  background: #1997bc;
}

.carousel-div-container {
  margin: 0 auto; /* Center the carousel */

}
.slider-container {
  
  width:95%;
  margin: 0 auto; /* Center the carousel */

}

.slider-card{
  // display:flex;
  // flex-direction:row;
  border:1px grey solid;

  span{
    font-size:12px;
    margin:-0.5px;
    margin-left:2px;
    white-space:nowrap;
    // overflow:auto;
    
  }

  .license-flex{
    display: inline-flex;
  }

  .rightIndent {
  position: absolute;
  left: 47%      
}
}

.slider-card-child{
  flex:1;  
}

.slideCounter{
    text-align: center;
    margin-right: 4%;
}


.blur-text-container{
  position: relative;

  .overlay-text {
      font-size: 18px;
      position: absolute;
      top: 33%;
      left: 33%;
      width: 100%;
      height: 100%;
  }
}

.blur-container {
  position: relative;
  // overflow: hidden; /* Ensures the blur doesn't overflow */
}

.blurred {
  filter: blur(5px); /* Adjust blur amount as needed */
}

// overriding modal to display it in the middle of the screen
.license-submission-modal {
  .modal {
      position: fixed;
      z-index: 2;
      top: 80px; // header height
      right: 0;
      bottom: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: rgba(255, 255, 255, 0.8);
      
      
    .modal-content {
        position: absolute;
        margin: auto;
        top: 15%;
        right: 0;
        bottom: 0;
        left: 0;
        width: 910px;
        height: 420px;

        background: #fff;
        max-width: 910px; // max "page" width
        overflow: auto;
        padding: 2em;
        margin: 20px auto;
        border: 1px solid #666;
        border-radius: 5px;
        box-shadow: 0 0 5px #666;
    }

    .modal-header-button {
        display: flex;
        justify-content: space-between;
        align-items: center; /* Optional: align items vertically centered */
        font-size: 1.25em;
    }

    form {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      font-size: 1.25em;

      h1, footer {
          flex-basis: 100%;
      }

      .field {
          flex-grow: 1;
          flex-shrink: 0;
      }

      .field-license-name {
          flex-basis: 100%
      }

      .field-license-addr {
          flex-basis: calc(100% - 30em)
      }

      .field-license-city {
          flex-basis: 15em;
      }

      .field-license-state {
          flex-basis: 5em;
      }

      .field-license-zip {
          flex-basis: 10em;
      }

      .field-license-id {
          flex-basis: 38.5%;
      }

      .field-license-category {
          flex-basis: 38.5%;
      }
  }

  }
}