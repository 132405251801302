.main-container{
    h1{
        text-align: center;
    }
}
.filter-container {
    display:flex;
    flex-wrap:nowrap;
    margin-bottom:10px;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    // margin: 0 auto;
    // width:max-content;
    // justify-content: center; /* Changed from center to flex-start */



    label {
        background: #c6c6c6;
        padding: .5em 1em;
        padding-top: .67em;
        margin: 0;
        border-radius: 5px 0 0 5px;
        color: #fff;
        font-size: 0.9em;
      }
      label + input {
        border-radius: 0 5px 5px 0;
      }
}

.filter-container select {
    margin-right:2px;
    text-align: center;
    text-align-last: center;
    -moz-text-align-last: center;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding-right: 25px; /* Space for custom dropdown arrow */
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position: right 5px top 50%;
  }

.show-more-container {
    align-items: center;
    justify-content: center;
    text-align:center;

}
.license-form {
    justify-content: center;
    align-items: center;
    display:flex;
    font-size:14px;

    .business-name-col td{
        overflow-wrap:break-word;
        word-wrap:break-word;
        white-space:normal;
    }

    .cell-wrap {
        min-width:220px;
        max-width: 250px; /* Adjust as needed */
        word-wrap: break-word;
        overflow-wrap: break-word;
        white-space: normal;
      }

    .cell-wrap-flex {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .my-table {
        width: 100%;
        border-collapse: collapse;
      }
      
      .my-table th, .my-table td {
        border: 1px solid #ddd;
        padding: 8px;
      }
      
      .my-table th {
        background-color: #f2f2f2;
        text-align: left;
        max-width:300px;
      }

      .my-table td {
        text-align: left;
        max-width:300px;
        overflow-wrap:break-word;
        word-wrap:break-word;
        // white-space:normal;
      }

      .note-button{
        padding: 0 .5em; 
        text-transform: none;
        color: #1997bc; 
        background: none;
    }

    .see-more-button{
        margin-top: 15px;
        background-color: #1997bc;
    }
    .sorter {
        position: relative;
        display: inline-block;
        width: 1em;
        height: 1em;
        color: #c6c6c6;
    
        .active {
          color: black;
        }
    
        .sorter-up {
          position: absolute;
          top: -.25em;
        }
    
        .sorter-dn {
          position: absolute;
          bottom: -.25em;
        }
      }

    // .show-detail-button{
    //     font-size: 16px;
    // }
}

// overriding modal to display it in the middle of the screen
.license-modal {
    .modal {
        position: fixed;
        z-index: 2;
        top: 80px; // header height
        right: 0;
        bottom: 0;
        left: 0;
        height: 750px;
        width: 100%;
        background: rgba(255, 255, 255, 0.8);
        
        
        .modal-content {
            position: absolute;
            margin: auto;
            top: 0;
            right: 0;
            bottom: 10%;
            left: 0;
            width: 910px;

            background: #fff;
            max-width: 910px; // max "page" width
            overflow: auto;
            padding: 2em;
            margin: 20px auto;
            border: 1px solid #666;
            border-radius: 5px;
            box-shadow: 0 0 5px #666;
        }

        .license-header-button {
            display: flex;
            justify-content: space-between;
            align-items: center; /* Optional: align items vertically centered */
        }

        .license-verification-data {
            display: flex;
            flex-direction: row; /* Flex direction set to row */
        }
        .license-verification-data2 {
            display: flex;
            flex-direction: row; /* Flex direction set to row */
        }
          
        .data-column {
            flex: 1; /* Equal width for each column */
            padding: 16px; /* Adjust padding as needed */
        }
          
        .data-column:last-child {
            border-right: none; /* Remove border from last column */
        }
          
        .data-column h2 {
            margin-top: 0;
            margin-bottom: 16px;
            font-size: 18px;
        }
          
        .data-column span {
            display: block;
            margin-bottom: 8px;
        }

        .metadata-column {
            // display:flex;
            flex:1;
            padding: 16px; /* Adjust padding as needed */
            
        }
          

          
        .bRight {
            position: absolute;
            left: 51.7%      
        }
    }
}
