.modal {

  position: fixed;
  z-index: 2;
  top: 80px; // header height
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.8);


  .modal-content {
    background: #fff;
    max-width: 910px; // max "page" width
    max-height: calc( 100vh - 80px - 40px );
    overflow: auto;
    padding: 2em;
    margin: 20px auto;
    border: 1px solid #666;
    border-radius: 5px;
    box-shadow: 0 0 5px #666;
  }

  h1 {
    margin-top: 0;
  }

  footer {
    text-align: center;
  }
}
@media(max-width: 910px){
  .modal .modal-content {
    margin: 20px 10px;
  }
}