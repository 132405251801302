
.multi-button-switcher{
  display: inline-block;
  margin-bottom: .25em;

  button {
    background: #fff;
    padding: .5em 1em;
    margin: 0;
    color: #000;
    font-size: 0.9em;
    border-radius: 0;
    position: relative;
    border: 1px solid #c6c6c6;
    border-width: 1px 0;

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 5px;
      width: 0;
      border-left: 1px solid #c6c6c6;
      height: calc( 100% - 10px );
    }

    &:first-child {
      border-radius: 5px 0 0 5px;
      border-left-width: 1px;
      &::before { display: none; }
    }

    &:last-child {
      border-radius: 0 5px 5px 0;
      border-right-width: 1px;
    }

    &.active {
      background: #1997bc;
      border-color: #1997bc;
      color: #fff;
      &::before, & + button::before  { display: none; }
    }
     &:focus {
       z-index: 1;
     }
  }
}