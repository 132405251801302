/* Container to hold badges pinned to the top-left */
.badge-container {
    position: relative;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: row;
    gap: 4px; /* Add some space between badges */
    max-width: fit-content;
    margin-left: 5px;
    margin-right: 5px;
}

/* Style for each badge icon */
.badge-icon {
    text-transform: none;
    color: none; 
    background: none;
}

/* Specific styles for each type of badge */
.approved {
    color: green;
}

.rejected {
    color: red;
}

.approved_with_comments {
    color: orange;
}

.manual_review_recommended {
    color: gold;
}

.badge-icon {
    position: relative;
    cursor: pointer; /* Optional to change the cursor style */
}

/* Tooltip container */
.badge-icon::after {
    content: attr(data-title);
    position: absolute;
    bottom: -150%; /* Position above the icon */
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 4px 8px;
    border-radius: 4px;
    white-space: nowrap;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease;
    z-index: 1;
    font-size: 12px;
}

/* Show tooltip on hover */
.badge-icon:hover::after {
    opacity: 1;
}