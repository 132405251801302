
.inquiry-navigator {
  position: sticky;
  left: 0;
  width: 100%;
  background: #fff;
  z-index: 1;
  height: 38px;

  .inquiry-navigator-content-wrap {
    max-width: 990px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;

    & > div {
      display: inline-block;
      padding-right: 1em;
      position: relative;

      &:last-child {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-right: 0;
        min-width: 0;
      }
    }
  }

  .inquiry-button {
    position:relative;
    float:right;
    justify-content: space-around;
    margin-left: auto;
  }

  .modal {
    width: 50vw;
    height: 33vw;
    display:flex;
    position: relative;
    top: 25vh;
    left:20vh;
    background: white;
    border: 1px solid #ccc;
    border-radius: 9px;
    transition: 1.1s ease-out;
    box-shadow: -2rem 2rem 2rem rgba(black, 0.2);
    filter: blur(0);
    transform: scale(1);
    opacity: 1;
    visibility: visible;
  
    &.red {
      background: black;
      color: red;
    }
    &.blue {
      background: blue;
      color: red;
    }
  
    &.off {
      opacity: 0;
      visibility: hidden;
      filter: blur(8px);
      transform: scale(0.33);
      box-shadow: 1rem 0 0 rgba(black, 0.2);
    }
    @supports (offset-rotate: 0deg) {
      offset-rotate: 0deg;
      offset-path: path("M 250,100 S -300,500 -700,-200");
      &.off {
        offset-distance: 100%;
      }
    }
    @media (prefers-reduced-motion) {
      offset-path: none;
    }
    h2 {
      border-bottom: 1px solid #ccc;
      padding: 1rem;
      margin: 0;
    }
    .content {
      padding: 1rem;
    }
    .actions {
      border-top: 1px solid #ccc;
      background: #eee;
      padding: 0.5rem 1rem;
      button {
        border: 0;
        background: #78f89f;
        border-radius: 5px;
        padding: 0.5rem 1rem;
        font-size: 0.8rem;
        line-height: 1;
      }
    }
  }

  .match-selector {
    display: inline-block;
    position: relative;
    margin-left: 1em;
    border: 1px solid #c6c6c6;
    flex: 1;
    padding: 0.3em 1em;
    cursor: default;
    min-width: 0;

    &::after {
      content: '‣';
      font-size: 2.8em;
      position: absolute;
      top: 0.4em;
      line-height: 0em;
      right: 3px;
      transform: rotate(90deg);
      color: #c6c6c6;
    }

    strong {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      display: inline-block;
    }

    ul {
      display: none;
      position: absolute;
      top: 100%;
      left: -1px;
      border: 1px solid #c3c3c3;
      padding: 0;
      margin: 0;
      width: calc(100% + 2px);
      background: #fff;
      max-height: 400px;
      overflow: auto;
    }

    &:hover ul {
      display: block;
    }

    &.collapse ul {
      display: none;
    }

    li {
      list-style: none;
      display: flex;
      flex-direction: row;
      align-items: center;

      &:hover {
        background: #eff9ff;
      }
    }

    a {
      padding: .5em 1em;
      text-decoration: none;
      display: block;
      flex: 1;
    }

    .viewed-note {
      color: gray;
      padding: 0 .5em;
      font-style: italic;
      font-size: .9em;
    }

    .address {
      font-weight: normal;
      color: #1d1d26;
      font-size: 0.8em;
      padding: 0 .5em;
    }
  }
}
